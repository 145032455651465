import React from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  Link,
  makeStyles,
} from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';

import { VscBrowser } from 'react-icons/vsc';

import {} from 'react-feather';
import { BorderBottom } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  headerSection: {
    background: 'linear-gradient(270deg, #F9804E -0.01%, #E3191B 105.12%)',
    boxShadow: '6.79395px 6.79395px 30.1953px rgba(24, 24, 24, 0.18)',
    top: '68px',
    position: 'absolute',
    width: '100%',
    left: '0',
    zIndex: '9',
    maxWidth: '100%',
    overflow: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },

  iconText: {
    width: 14,
    height: 14,
    marginRight: '8px',
    color: '#fff',
  },
  iconBox: {
    display: 'flex',
  },
}));

export default function Header() {
  const classes = useStyles();
  const { account } = useWeb3React();

  return (
    <>
      <Box className={classes.headerSection}>
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Box
              className='secondaryHeader'
              style={{ justifyContent: 'start' }}
            >
              <RouterLink to='/orders'>
                <Box className={classes.iconBox}>
                  <Typography variant='body2' className='iconText'>
                    Marketplace
                  </Typography>
                </Box>
              </RouterLink>
              {/* {account && (
                <RouterLink to='/my-nft'>
                  <Box className={classes.iconBox} ml={5}>
                    <Typography variant='body2' className='iconText'>
                      My NFT
                    </Typography>
                  </Box>
                </RouterLink>
              )} */}
              {/* <Link href="/my-auction">
                {' '}
                <Box className={classes.iconBox}>
                  <Typography variant="body2" className="iconText">
                    Auction
                  </Typography>
                </Box>
              </Link> */}

              {/* <Link href="/wallet">
                <Box className={classes.iconBox}>
                  <Typography variant="body2" className="iconText">
                    My Wallet
                  </Typography>
                </Box>
              </Link> */}
              {/* <Link href="/account-review">
                <Box className={classes.iconBox}>
                  <Typography variant="body2" className="iconText">
                    Review
                  </Typography>
                </Box>
              </Link> */}
              {/* <Link href="/favourites">
                <Box className={classes.iconBox}>
                  <Typography variant="body2" className="iconText">
                    Favourites
                  </Typography>
                </Box>
              </Link> */}
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
