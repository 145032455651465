import React, { useContext, useState, useEffect } from 'react';

import { BsArrowRight } from 'react-icons/bs';

import { Container, Typography, Box, Grid, Link } from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import { FiEdit } from 'react-icons/fi';
import { MdRateReview } from 'react-icons/md';

import ProfileCard from 'src/component/ProfileCard';
import Slider from 'react-slick';
import { UserContext } from 'src/context/User';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import apiConfig from 'src/config/ApiConfig';
import { NFTTOKEN, OpenMarketplace } from 'src/constants';
import OpenMarketplaceABI from 'src/ABI/OpenMarketplaceABI.json';
import { useWeb3React } from '@web3-react/core';
import { getContract } from 'src/utils';

const useStyles = makeStyles((theme) => ({
  wallet: {
    background: '#15143D',
    paddingTop: '130px',
    paddingBottom: theme.spacing(6),
  },

  iconSocial: {
    ontSize: '15px',
    color: '#999999',
    marginRight: '8px',
  },
  dataIcon: {
    fontSize: '18px',
    color: '#999999',
  },
  dataText: {
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: '600',
    paddingLeft: '10px',
    color: '#999999',
  },
  dataNumber: {
    float: 'right',
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: '600',
    color: '#999999',
  },
  walletSet: {
    padding: '0 15px 0 0',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
  sectionTitleHead: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    margin: '10px 0 ',
    padding: '0 0',
    width: '100%',
  },
}));
export default function Profile() {
  const user = useContext(UserContext);
  const [orderList, setOrderList] = useState([]);
  const [bidList, setBidList] = useState([]);
  const classes = useStyles();
  const settings = {
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    className: 'recomended',
    autoplay: false,
    infinite: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          centerPadding: '20px',
          autoplay: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          centerPadding: '20px',
          autoplay: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: '20px',
          autoplay: false,
        },
      },
    ],
  };
  const settings1 = {
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    className: 'recomended',
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          centerPadding: '20px',
          autoplay: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          centerPadding: '20px',
          autoplay: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: '20px',
          autoplay: false,
        },
      },
    ],
  };

  const { account, library } = useWeb3React();

  const token = sessionStorage.getItem('token');
  useEffect(() => {
    getDataHandler();
    // getbidListHandler();
  }, [user]);

  async function rejectBid(data) {
    const contractObj = await getContract(
      OpenMarketplace,
      OpenMarketplaceABI,
      library,
      account
    );
    // cancelBid(nftAddress, tokenId)
    console.log(data.nftId.tokenId);

    try {
      const cancelBid = await contractObj.cancelBid(
        NFTTOKEN,
        data.nftId.tokenId
      );

      await cancelBid.wait();

      axios({
        method: 'put',
        url: apiConfig.rejectBid,
        headers: {
          token: window.sessionStorage.getItem('token'),
        },
      })
        .then((res) => console.log(res.data.result))
        .catch((err) => console.log(err));
    } catch (err) {
      window.alert(err.message);
      console.log(err);
    }
  }

  const getDataHandler = async () => {
    try {
      const res = await axios.get(apiConfig.buyList, {
        headers: {
          token,
        },
      });
      if (res.data.response_code === 200) {
        setOrderList(res.data.result);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const getbidListHandler = async () => {
    try {
      const res = await axios.get(apiConfig.bidList);
      if (res.data.response_code === 200) {
        const result = res.data.result.filter(
          (data) => data.userId == user.userData._id
        );
        setBidList(result);
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <Box className={classes.wallet} mb={5}>
      <Container maxWidth='lg'>
        <Box>
          <Box className='usersView m-b-0'>
            <figure className='figure profilePicture'>
              <img
                src={'/images/userbg.png'}
                className='img-responsive'
                alt=''
              />
            </figure>
            <Box className='editButton'>
              <RouterLink to='/edit-profile' title='Edit Profile'>
                <FiEdit
                  style={{
                    fontSize: '22px',
                    color: '#fff',
                    marginRight: '10px',
                  }}
                />
              </RouterLink>
              {/* <RouterLink to="/account-review" title="Account Review">
                <MdRateReview style={{ fontSize: '22px', color: '#fff' }} />
              </RouterLink> */}
            </Box>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3}>
              <Box className='userPic '>
                <figure>
                  <img
                    src={
                      user.userData && user.userData.profilePic
                        ? user.userData.profilePic
                        : 'images/profile2.jpg'
                    }
                    alt='error'
                    className='profile2'
                  />
                </figure>
                <Box>
                  <Typography variant='h4' className='SectionTitle m-b-5'>
                    {user.userData && user.userData.userName}
                  </Typography>
                </Box>
                {/* <Box>
                  <InstagramIcon className={classes.iconSocial} />{" "}
                  <TwitterIcon className={classes.iconSocial} />{" "}
                  <YouTubeIcon className={classes.iconSocial} />
                </Box> */}
                {/* <Box className="p-t-20">
                  <Box className="p-t-5 p-b-10">
                    <ShoppingCartIcon className={classes.dataIcon} />

                    <span className={classes.dataText}> Weeky Sale </span>

                    <span className={classes.dataNumber}> 25+ </span>
                  </Box>

                  <Box className="p-t-5 p-b-10">
                    <FeaturedVideoIcon className={classes.dataIcon} />
                    <span className={classes.dataText}>Featured NFT</span>

                    <span className={classes.dataNumber}> 510+ </span>
                  </Box>

                  <Box className="p-t-5 p-b-10">
                    <LoyaltyIcon className={classes.dataIcon} />

                    <span className={classes.dataText}>NFT on Sale </span>
                    <span className={classes.dataNumber}> 300 </span>
                  </Box>
                  <Box className="p-t-5 p-b-10">
                    <DashboardIcon className={classes.dataIcon} />
                    <span className={classes.dataText}>Dashboard Item</span>
                    <span className={classes.dataNumber}> 25+ </span>
                  </Box>
                </Box>
               */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} md={9}>
              <Grid container spacing={4} className='m-t-25'>
                <Slider {...settings} className='width100'>
                  {bidList.map((data, i) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        lg={12}
                        key={i}
                        className='walletSet'
                      >
                        <ProfileCard
                          // rejectBid={(data) => rejectBid(data)}
                          data={data}
                          type='hasOffer'
                          index={i}
                        />
                      </Grid>
                    );
                  })}
                </Slider>
              </Grid>
            </Grid>
          </Grid>

          {/* <Profilecomponent />
        <Profilecomponent2 /> */}
        </Box>
      </Container>
      <Box mt={3} mb={2}>
        <Container fixed>
          <Grid container spacing={2} style={{ display: 'block' }}>
            <Grid container>
              <Box className={classes.sectionTitleHead}>
                <Typography variant='h4' className='SectionTitle m-b-5'>
                  My Orders{' '}
                </Typography>
                {/* <Button
                  variant="text"
                  color="primary"
                  className={classes.button}
                  endIcon={<BsArrowRight />}
                >
                  More
                </Button> */}
              </Box>
            </Grid>
            <Grid container spacing={2}>
              <Slider {...settings} className='width100'>
                {orderList.map((data, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      key={i}
                      className='walletSet'
                    >
                      <ProfileCard data={data} type='auctionLowBid' index={i} />
                    </Grid>
                  );
                })}
              </Slider>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
