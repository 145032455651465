import React from "react";
import { makeStyles, Box, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: "#000",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    padding: theme.spacing(3),
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 2000,
  },
  progressBar: {
    height: "3px",
  },
  loaderimg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box style={{ position: "relative", width: "100%", height: "100%" }}>
        {/* <LinearProgress height={10} /> */}
        <img
          className={classes.loaderimg}
          src="/images/loader.gif"
          alt=""
          style={{ width: "300px", maxWidth: "90%" }}
        />
      </Box>
    </div>
  );
}
