import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  List,
  ListItem,
  Link,
  makeStyles,
} from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import { Link as RouterLink } from "react-router-dom";

import {} from "react-feather";
import Logo from "./../../component/Logo";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "#000",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.footerSection}>
        <Box style={{ margin: "20px 10px 0" }}>
          <Container maxWidth="lg">
            <Grid
              container
              style={{ color: "white" }}
              justify="space-around"
              spacing={1}
            >
              <Grid item xs={12} sm={4} md={4}>
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  alignItems="flex-start"
                >
                  <Grid item>
                    {" "}
                    <a href="https://tiar.io">
                      <Logo width="100" />{" "}
                    </a>
                  </Grid>
                  <Grid item>
                    {" "}
                    <Box mt={2}>
                      <Typography
                        style={{
                          fontSize: "15px",
                          fontWeight: "normal",
                          width: "90%",
                        }}
                      >
                        The Indian Art Revolution is a decentralised network
                        which aims to bring innovation in the art, sports and
                        entertainment industry in India through blockchain
                        technology.
                      </Typography>{" "}
                    </Box>
                    <Box mt={2} className="sociaLinks">
                      <List>
                        <ListItem>
                          <Link href="mailto:Support@tiar.io">
                            <EmailIcon />
                          </Link>
                          <RouterLink to="/">
                            <FacebookIcon />
                          </RouterLink>
                          <RouterLink to="/">
                            <TelegramIcon />
                          </RouterLink>
                          <RouterLink to="/">
                            <TwitterIcon />
                          </RouterLink>
                          <RouterLink to="/">
                            <InstagramIcon />
                          </RouterLink>
                        </ListItem>
                      </List>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={8} md={8}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={4} md={4}>
                    <Typography className="footerTitle" variant="h4">
                      My Account
                    </Typography>

                    <List className="listingFooter">
                      <ListItem>
                        <RouterLink to="/profile">My Profile</RouterLink>
                      </ListItem>
                      <ListItem>
                        <RouterLink to="/orders">Browse</RouterLink>
                      </ListItem>
                      {/* <ListItem><Link href="/favourites">My Favourites</Link></ListItem> */}
                      {/* <ListItem><Link href="/account-review">My Account Setting</Link></ListItem> */}
                      {/* <ListItem><Link href="/wallet">My Wallet</Link></ListItem> */}
                    </List>
                  </Grid>

                  <Grid item xs={6} sm={4} md={4}>
                    <Typography className="footerTitle" variant="h4">
                      Company
                    </Typography>
                    <List className="listingFooter">
                      <ListItem>
                        <Link href="https://tiar.io/" target="_blank">
                          Company
                        </Link>
                      </ListItem>
                      <ListItem>
                        <RouterLink to="">Careers</RouterLink>
                      </ListItem>
                      <ListItem>
                        <RouterLink to="/">About</RouterLink>
                      </ListItem>
                      <ListItem>
                        <RouterLink to="/">Blog</RouterLink>
                      </ListItem>
                    </List>{" "}
                  </Grid>
                  <Grid item xs={6} sm={4} md={4}>
                    <Typography className="footerTitle" variant="h4">
                      Terms Of Use
                    </Typography>
                    <List className="listingFooter">
                      <ListItem>
                        <RouterLink to="/">Legal Notice</RouterLink>
                      </ListItem>
                      <ListItem>
                        <RouterLink to="/">Dislaimer</RouterLink>
                      </ListItem>
                      <ListItem>
                        <RouterLink to="/">Terms & Conditions</RouterLink>
                      </ListItem>
                      <ListItem>
                        <RouterLink to="/">Privacy Policy</RouterLink>
                      </ListItem>
                      <ListItem>
                        <RouterLink to="/">Cookies Policy</RouterLink>
                      </ListItem>
                    </List>{" "}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box className="lowerFooter">
                  <Typography
                    style={{ fontSize: "12px", fontWeight: "normal" }}
                  >
                    copyright 2021 | NFT Marketplace
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
