import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  InputBase,
  Drawer,
  Grid,
  Link,
  MenuItem,
  Box,
  Container,
  List,
  Typography,
  fade,
  Hidden,
  TextField,
  Menu,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Logo from './../../component/Logo';
import { RiAdminLine } from 'react-icons/ri';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ListItem } from '@material-ui/core';
import { UserContext } from 'src/context/User';
import { useWeb3React } from '@web3-react/core';
import ButtonCircularProgress from 'src/component/ButtonCircularProgress';
import axios from 'axios';
import apiConfig from 'src/config/ApiConfig';
import { ACTIVE_NETWORK } from 'src/constants';
const headersData = [
  {
    label: 'Home',
    href: '/',
  },
  // {
  //   label: 'Create',
  //   href: '/create-nft',
  // },
  // {
  //   label: 'Create',
  //   href: '/create-nft',
  // },
  // {
  //   label: "Connect",
  //   href: "/Connect",
  // },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: '13px',
    // height: "48px",
    fontWeight: '400',
    borderRadius: 0,
    marginLeft: '16px',
    minWidth: 'auto',
    color: '#fffff',
    padding: '10px 24px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    '@media (max-width: 900px)': {
      fontStyle: 'normal',
      letterSpacing: '-0.6px',
      lineHeight: '1.75',
    },
    '&:active': {
      color: '#1ed760',
    },
    '&:last-child': {
      backgroundColor: '#1ed760',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    '@media (max-width: 900px)': {
      paddingLeft: '75px',
      paddingRight: '20px',
      height: '100%',
    },
  },
  maindrawer: {
    height: '100%',
    background: '#0c0731',
    width: '260px',
  },
  logoDrawer: {
    paddingLeft: '10px',
    width: '130px',
    marginBottom: '30px',
  },
  drawerContainer: {
    padding: '20px 0px ',
    height: '100%',
    background: '#000',
    width: '260px',
  },
  drawericon: {
    color: '#000',
    padding: 0,

    fontSize: '25px',
  },
  logoImg: {
    width: '75px',
    // height: '44.5px',
    margin: ' 14px 15px 11px 0px',
    objectFit: 'contain',
    '@media (max-width: 500px)': {
      margin: ' 11px 1px 3px 0px',
      width: '52px',
    },
  },
  menuMobile: {
    fontSize: '16px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: '-0.6px',
    lineHeight: '1.75',
    color: '#fff',
    borderBottom: '1px solid #3e3e3e',
    padding: '16px',
  },
  paper1: {
    background: 'black',
    color: 'white',
  },
  containerHeight: {
    height: '100%',
  },
  mainHeader: {
    justifyContent: 'space-between',
    padding: '0px',
  },
  search: {
    height: '36px',
    // marginTop: "7px",
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 20,
    width: '160px',
    maxWidth: '257px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: '257px',
    },
  },
  searchIcon: {
    fontSize: '16px',
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    fontSize: '16px',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: '13px',
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100px',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%',
      },
    },
  },
}));

export default function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useContext(UserContext);
  const [open1, setOpen1] = React.useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isErrorMsg, setIsErrorMsg] = useState(false);
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);
  const { chainId } = useWeb3React();

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    header,
    menuMobile,
    menuButton,
    toolbar,
    drawerContainer,
    drawericon,
    logoImg,
    logoDrawer,
    containerHeight,
    search,
    searchIcon,
    mainHeader,
    inputInput,
    inputRoot,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  useEffect(() => {
    if (user.isLogin) {
      setOpen(false);
    }
  }, [user.isLogin]);

  const displayDesktop = () => {
    return (
      <Container maxWidth='lg'>
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction='row'
            justify='flex-end'
            alignItems='center'
            style={{ paddingLeft: '0px' }}
          >
            <div className={search}>
              <div className={searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder='Search…'
                classes={{
                  root: inputRoot,
                  input: inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            {getMenuButtons()}
            {(user.userType === 'ADMIN' || user.userType === 'SUBADMIN') && (
              <Button
                color='inherit'
                to='/create'
                component={RouterLink}
                className={menuButton}
              >
                {'Create'}
              </Button>
            )}
            {user.isLogin ? (
              <Button
                variant='contained'
                color='small'
                color='primary'
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={handleClick}
              >
                Profile
              </Button>
            ) : (
              <Button
                variant='contained'
                color='small'
                color='primary'
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={handleClickOpen}
              >
                Connect
              </Button>
            )}
            {/* <Button variant="contained" size="small" color="secondary" onClick={handleClickOpen}>
        Connect
      </Button> */}
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img
              className={logoDrawer}
              src='https://secureservercdn.net/160.153.137.14/dhl.277.myftpupload.com/wp-content/uploads/2021/08/Group-2.svg'
              alt=''
            />

            {getDrawerChoices()}

            <Button
              variant='contained'
              color='primary'
              size='small'
              // className="MobileButton"
              onClick={handleClickOpen}
              style={{ marginTop: '15px', marginLeft: '15px' }}
            >
              Connect
            </Button>

            {/* <Button
              variant='contained'
              color='primary'
              size='small'
              // className="MobileButton"
              onClick={handleClickOpen}
              style={{ marginTop: '15px', marginLeft: '15px' }}
            >
              Connect
            </Button> */}
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}>
            <div className={search}>
              <div className={searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder='Search…'
                classes={{
                  root: inputRoot,
                  input: inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                // edge: "start",
                color: 'inherit',
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width='60px'
                height='60px'
                style={{ color: '#f1521e', fontSize: '30px' }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Link
            {...{
              component: RouterLink,
              to: href,
              color: 'inherit',
              style: { textDecoration: 'none' },
              key: label,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Link>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <RouterLink to='/'>
        <Logo className='logoImg' width='150' />
      </RouterLink>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: 'inherit',
              to: href,
              component: RouterLink,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  const addAdminHandler = async () => {
    setIsErrorMsg(false);
    const token = sessionStorage.getItem('token');
    setIsSubmit(true);
    if (walletAddress !== '' && name !== '') {
      setIsUpdate(true);
      try {
        const res = await axios.post(
          apiConfig.addSubAdmin,
          {
            walletAddress,
            name,
          },
          {
            headers: {
              token,
            },
          }
        );
        if (res.data.response_code === 200) {
          setErrorMsg(res.data.response_message);
          setIsErrorMsg(false);
        } else {
          setIsErrorMsg(true);
          setErrorMsg(res.data.response_message);
        }
        setIsUpdate(false);
      } catch (error) {
        setErrorMsg(error.message);
        setIsUpdate(false);
        setIsErrorMsg(true);

        console.log('ERROR', error);
      }
    }
  };

  useEffect(() => {
    if (chainId && ACTIVE_NETWORK && chainId !== ACTIVE_NETWORK) {
      console.log('--');
      setIsWrongNetwork(true);
    } else {
      setIsWrongNetwork(false);
    }
  }, [chainId, ACTIVE_NETWORK]);

  return (
    <>
      <AppBar
        position='absolute'
        className={history.location.pathname !== '/' ? 'InnerHeader' : 'Header'}
        elevation={0}
        style={
          history.location.pathname !== '/'
            ? { backgroundColor: '#15143D' }
            : { backgroundColor: 'transparent' }
        }
      >
        <Container
          maxWidth={history.location.pathname !== '/' ? 'lg' : 'fixed'}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        {user.isLogin ? (
          <>
            <MenuItem>
              <RouterLink to='/profile' style={{ color: 'white' }}>
                My Profile
              </RouterLink>
            </MenuItem>
            {(user.userType === 'ADMIN' || user.userType === 'SUBADMIN') && (
              <>
                <MenuItem>
                  <RouterLink to='/my-nft' style={{ color: 'white' }}>
                    My Nft
                  </RouterLink>
                </MenuItem>
                <MenuItem>
                  <RouterLink to='/collection' style={{ color: 'white' }}>
                    Collection List
                  </RouterLink>
                </MenuItem>
                {user.userType === 'ADMIN' && (
                  <MenuItem onClick={handleClickOpen1}>
                    <Link>Add Admin</Link>
                  </MenuItem>
                )}
                <MenuItem>
                  <RouterLink to='/admin'>Admin List</RouterLink>
                </MenuItem>
              </>
            )}
          </>
        ) : (
          <MenuItem onClick={handleClickOpen}>Connect</MenuItem>
        )}
      </Menu>

      <Dialog
        open={isWrongNetwork}
        className='walletdailog'
        fullWidth
        minWidth={'md'}
        // onClose={handleClose}
        // aria-labelledby="alert-dialog-title"
        // aria-describedby="alert-dialog-description"
      >
        <DialogTitle id='alert-dialog-title' className='dailogTitle'>
          {'Network Error'}
        </DialogTitle>
        <DialogContent className=''>
          <DialogContentText id='alert-dialog-description'>
            <Box className='walletlist'>
              <Typography>
                Please swich to Binance smartchain mainnet
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        className='walletdailog'
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' className='dailogTitle'>
          {'Select a Wallet'}
        </DialogTitle>
        <DialogContent className='loginModal'>
          <DialogContentText id='alert-dialog-description'>
            <Box className='walletlist'>
              <List>
                <ListItem>
                  <Link onClick={() => user.connectWallet()}>
                    <Typography variant='h3'>Metamask</Typography>
                    <img src='/images/metamask.png' alt='metamask' />
                  </Link>
                </ListItem>
                {/* <ListItem>
                  <Link href="#">
                    <Typography variant="h3">Wallet Connect</Typography>
                    <img src="/images/wt.png" alt="metamask" />
                  </Link>
                </ListItem> */}
                {/* <ListItem>
                  <Link href="#">
                    <Typography variant="h3">Binance smart</Typography>
                    <img src="/images/binance.png" alt="metamask" />
                  </Link>
                </ListItem> */}
              </List>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions className='dailogFooter'>
          <a onClick={handleClose} color='primary' autoFocus>
            Close
          </a>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open1}
        keepMounted
        maxWidth='sm'
        onClose={handleClose2}
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
      >
        <Grid container spacing={0} alignItems='stretch'>
          <Hidden smDown>
            <Grid item xs={12} sm={4}>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  height: '100%',
                }}
              >
                <img
                  src='/images/wallet.png'
                  alt=''
                  style={{ display: 'block', height: '100%' }}
                />
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={8}>
            <Box style={{ height: '100%', background: '#15143D' }}>
              <Box pt={7} textAlign='center'>
                <RiAdminLine style={{ color: '#fff', fontSize: '40px' }} />
              </Box>
              <Box px={3} mt={5} pb={3}>
                <Typography variant='v4'>Add admin details</Typography>
                <TextField
                  label='User Name'
                  variant='outlined'
                  style={{ margin: '8px 0' }}
                  fullWidth
                  className='loginInput'
                  margin='normal'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  error={isSubmit && name === ''}
                  helperText={isSubmit && name === '' && 'Please enter name'}
                />
                <TextField
                  label='Wallet Address'
                  variant='outlined'
                  style={{ margin: '8px 0' }}
                  fullWidth
                  margin='normal'
                  className='loginInput'
                  onChange={(e) => setWalletAddress(e.target.value)}
                  value={walletAddress}
                  error={isSubmit && walletAddress === ''}
                  helperText={
                    isSubmit &&
                    walletAddress === '' &&
                    'Please enter wallet address'
                  }
                />
                <Box>
                  <Typography style={isErrorMsg ? { color: 'red' } : {}}>
                    {errorMsg}
                  </Typography>
                </Box>
                <Button
                  variant='contained'
                  color='primary'
                  size='large'
                  fullWidth
                  style={{ margin: '15px 0 0' }}
                  disabled={isUpdate}
                  onClick={addAdminHandler}
                >
                  Add Admin {isUpdate && <ButtonCircularProgress />}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
