// let baseurl = 'https://backend.tiar.io';
let baseurl = `${process.env.REACT_APP_API_URL}`;
let user = `${baseurl}/api/v1/user`;
let nft = `${baseurl}/api/v1/nft`;
let admin = `${baseurl}/api/v1/admin`;

const apiConfig = {
  connectWallet: `${user}/connectWallet`,
  getProfile: `${user}/getProfile`,
  editProfile: `${user}/editProfile`,
  uploadNFT: `${nft}/uploadNFT`,
  addImage: `${nft}/addImage`,
  addNft: `${nft}/addNft`,
  placeOrder: `${nft}/placeOrder`,
  nftWithoutOrderList: `${nft}/nftWithoutOrderList`,
  placeOrderList: `${nft}/placeOrderList`,
  placeOrderListById: `${nft}/placeOrderListById`,
  placeBid: `${nft}/placeBid`,
  sellOrder: `${nft}/sellOrder`,
  orderListParticular: `${nft}/orderListParticular`,
  rejectBid: `${nft}/rejectBid`,
  acceptBid: `${nft}/acceptBid`,
  bidList: `${nft}/bidList`,
  sellOrderList: `${nft}/sellOrderList`,
  buyOrder: `${nft}/buyOrder`,
  buyList: `${nft}/buyList`,
  updateOrder: `${nft}/updateOrder`,
  cancelOrder: `${nft}/cancelOrder`,

  redeem_unRedeem: `${user}/redeem_unRedeem`,
  userPlaceOrder: `${user}/userPlaceOrder`,

  addSubAdmin: `${admin}/addSubAdmin`,
  subAdminList: `${admin}/subAdminList`,
  createCollection: `${admin}/createCollection`,
  collectionList: `${admin}/collectionList`,
};

export default apiConfig;
