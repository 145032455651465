import React from 'react';

const Logo = (props) => {
  return (
    <img
      src="https://secureservercdn.net/160.153.137.14/dhl.277.myftpupload.com/wp-content/uploads/2021/08/Group-2.svg"
      alt="Logo"
      {...props}
    />
  );
};

export default Logo;
